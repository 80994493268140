import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import WhoYouAreButtons from './WhoYouAreButtons'

const SpacioHero = ({ data }) => {
    return (
        <div className="hero col-12">
            <div className="copy-content text-white span-7-mx">
                <h1>The best open house experiences begin with Spacio </h1>
                <p>
                    Spacio is the industry’s leading end-to-end open house app
                    lead generation system. Spacio starts with open house sign
                    in and provides you the lead management resources, tools,
                    and integration to convert guests into meaningful
                    relationships. Better relationships equals better business.
                </p>
                <div className="call-to-action">
                    I need an open house app solution for:
                </div>
                <WhoYouAreButtons path="spacio" />
            </div>
            <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                className="hero-illustration"
            />
        </div>
    )
}

const SpacioHeroRender = props => (
    <StaticQuery
        query={graphql`
            {
                file(relativePath: { eq: "spacioSolutionHero.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 502
                            placeholder: NONE
                            layout: CONSTRAINED
                        )
                    }
                }
            }
        `}
        render={data => <SpacioHero data={data} {...props} />}
    />
)

export default SpacioHeroRender
