import React from 'react'
import { Link } from 'gatsby'
import { appendUtmsFromSearchToUrl, getSearchParams } from '../utilities';

export default class WhoYouAreButtons extends React.Component {
    render() {
        const { additionalOpts, path } = this.props
        const isBoost = path.includes('boost');

        // gatsby pages are statically built, to add runtime params, use js on client side to append
        if (typeof document !== 'undefined' && isBoost) {
            let links = document.querySelectorAll("a[href*='/boost']");
            links.forEach((link) => {
                link.setAttribute('href', appendUtmsFromSearchToUrl(link.href, getSearchParams()));
            });
        }

        const LinkTag = (wholePath, label) => {
            return (isBoost ?
                <a className="button button-plain text-black" href={wholePath} >{label}</a> :
                <Link className="button button-plain text-black" to={wholePath}>{label}</Link>);
        }

        return (
            <div className="hero-buttons">
                <div className="buttons">
                    {LinkTag(`/agents/${path}`, 'Agent')}
                    {LinkTag(`/teams/${path}`, 'Team')}

                    {additionalOpts
                        ? additionalOpts.map(opt => (
                              <Link
                                  className="button button-plain text-black"
                                  key={opt.display}
                                  to={`/${opt.route}/${path}`}
                              >
                                  {opt.display}
                              </Link>
                          ))
                        : null}
                </div>

                {LinkTag(`/brokers/${path}`, 'Brokerage & Franchisors')}
            </div>
        )
    }
}
