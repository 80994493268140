import React from 'react'

import Layout from '../components/Layout'
import SpacioHero from '../components/SpacioHero'

const SpacioPage = () => (
    <Layout
        bodyClass="spacio"
        title="Open House Sign In App | Spacio by HomeSpotter"
        description="Spacio is the #1 Open House app for lead generation. Register guests, capture every lead and automate follow up."
        keywords="spacio, real estate, open house, app, sign in, automation, crm, marketing, follow up"
    >
        <SpacioHero />
    </Layout>
)

export default SpacioPage
